/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { MailOutlined} from "@ant-design/icons";

import insta from "../img/insta.png";
import fb from "../img/fb.png";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <h2>Pentru mai multe detalii contactați-ne:</h2>

        <div style={{ textAlign: "center", margin: "10px auto" }} id="footer">
          <a
            className="futerLink"
            title="Instagram"
            href="https://www.instagram.com/decortgro/?hl=en"
            target="_blank"
          >
            <img className="futerImg" alt="instagram" src={insta} />
          </a>
          <a
            className="futerLink"
            title="Facebook"
            href="https://www.facebook.com/TG.decor.romania/"
            target="_blank"
          >
            <img className="futerImg" alt="educards facebook" src={fb} />
          </a>
        </div>

        <div style={{ textAlign: "center" }} className="center-link">

          <MailOutlined />
          <a href="mailto:tgdecor@totalgravura.md"> tgdecor@totalgravura.md</a>
          <br />
          <div style={{ height: "10px" }}></div>
        </div>
      </div>
    );
  }
}
