import React, { Component } from 'react'
import Footer from './Footer'
import Header from './Header'

export default class Multumim extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div style={{
                    textAlign:"center",
                    margin:"100px auto"
                }}>
                    <h2>Vă mulțumim pentru comandă.</h2>
                    <h3>În scurt timp veți fi contactat de un manager.</h3>
                    <br/>
                    <a href='/'><h3 style={{color:"rgb(22,144,255)"}}>Pagina principală.</h3></a>
                </div>
                <Footer />
            </div>
        )
    }
}
