import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Divider, Input, Radio, message } from "antd";
import "../App.css";
import Multumim from "./Multumim";
import axios from 'axios'

const { TextArea } = Input;
const stripePromise = loadStripe(
  "pk_live_51IbjIWCtlzkD24uVGyURIG15LAv5ipevvmxAMYnqzH9DupbPBHhW6W75tm9s5f10aZMFbWHZQ5lHinclJHBZ2r7j00uKc1vkRz"
);

class Checkout extends Component {
  state = {
    basket: null,
    ids: null,
    fileName: null,
    thank_you: false,
    wrong: false,
    onlinePayment: 1,
    hasShippingInfo: false,
    confirmation:false,
  };

  componentDidMount = () => {
    this.setState({
      basket: JSON.parse(localStorage.getItem("tg-basket")),
      ids: JSON.parse(localStorage.getItem("tg-basketIds")),
      userName: JSON.parse(localStorage.getItem("tg-name")),
      userNumber: JSON.parse(localStorage.getItem("tg-number")),
      userEmail: JSON.parse(localStorage.getItem("tg-email")),
      userComment: JSON.parse(localStorage.getItem("tg-comment")),
      addressStreet: JSON.parse(localStorage.getItem("tg-address-street")),
      addressNumber: JSON.parse(localStorage.getItem("tg-address-number")),
      addressLocation: JSON.parse(localStorage.getItem("tg-address-location")),
      addressJudet: JSON.parse(localStorage.getItem("tg-address-judet")),
      addressCode: JSON.parse(localStorage.getItem("tg-address-code")),
    })

    const query = new URLSearchParams(window.location.search);
    if (query.get("canceled")) {
      message.error("Ceva nu a mers bine.");
    }
    if (query.get("success")) {
      this.createAWB();
    }

  };

  removeFromBasket = (index) => {
    let list = this.state.basket;
    let temp = this.state.ids;
    list.splice(index, 1);
    temp.splice(index, 1);
    if (list.length < 1) {
      localStorage.clear();
      this.setState({ basket: null });
    } else {
      this.setState({ basket: list, ids: temp });
      localStorage.setItem("tg-basket", JSON.stringify(list));
      localStorage.setItem("tg-basketIds", JSON.stringify(temp));
    }
  };

  updateComment = (e, index) => {
    let list = JSON.parse(localStorage.getItem("tg-basket"));
    list[index].comment = e.target.value;
    localStorage.setItem("tg-basket", JSON.stringify(list));
    this.setState({ basket: list });
  };

  addQuantity = (index) => {
    let list = this.state.basket;
    list[index].quantity = list[index].quantity + 1;
    localStorage.setItem("tg-basket", JSON.stringify(list));
    this.setState({ basket: list });
  };

  subQuantity = (index, quantity) => {
    if (quantity === 1) {
      this.removeFromBasket(index);
    } else {
      let list = this.state.basket;
      list[index].quantity = list[index].quantity - 1;
      localStorage.setItem("tg-basket", JSON.stringify(list));
      this.setState({ basket: list });
    }
  };

  updateName = (e) => {
    var temp = JSON.parse(localStorage.getItem("tg-name"));
    temp = e.target.value;
    localStorage.setItem("tg-name", JSON.stringify(temp));
    this.setState({ userName: temp });
  };
  updateNumber = (e) => {
    var temp = JSON.parse(localStorage.getItem("tg-number"));
    temp = e.target.value;
    localStorage.setItem("tg-number", JSON.stringify(temp));
    this.setState({ userNumber: temp });
  };
  updateEmail = (e) => {
    var temp = JSON.parse(localStorage.getItem("tg-email"));
    temp = e.target.value;
    localStorage.setItem("tg-email", JSON.stringify(temp));
    this.setState({ userEmail: temp });
  };
  updateUserComment = (e) => {
    var temp = JSON.parse(localStorage.getItem("tg-comment"));
    temp = e.target.value;
    localStorage.setItem("tg-comment", JSON.stringify(temp));
    this.setState({ userComment: temp });
  };

  updateStreet = (e) => {
    var temp = e.target.value;
    localStorage.setItem("tg-address-street", JSON.stringify(temp));
    this.setState({ addressStreet: temp });
  };

  updateStrNumber = (e) => {
    var temp = e.target.value;
    localStorage.setItem("tg-address-number", JSON.stringify(temp));
    this.setState({ addressNumber: temp });
  };

  updateLocation = (e) => {
    var temp = e.target.value;
    localStorage.setItem("tg-address-location", JSON.stringify(temp));
    this.setState({ addressLocation: temp });
  };

  updateJudet = (e) => {
    var temp = e.target.value;
    localStorage.setItem("tg-address-judet", JSON.stringify(temp));
    this.setState({ addressJudet: temp });
  };

  updatePostal = (e) => {
    var temp = e.target.value;
    localStorage.setItem("tg-address-code", JSON.stringify(temp));
    this.setState({ addressCode: temp });
  };

  getTotalQuantity = () => {
    var q = 1
    var list = JSON.parse(localStorage.getItem("tg-basket"))
    for(let i=0; i<list.length; i++) {
      q = q+ list[i].quantity
    }
    return q
  }

  buildFormData = (formData, data, parentKey) => {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  };

  jsonToFormData = (data) => {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  };

  checkFC = () => {

    if(this.state.addressStreet){
       var data = {
      username: "woodname",
      user_pass: "mebesobev",
      client_id: "7178047",
      serviciu: "Standard",
      localitate_dest: this.state.addressLocation,
      judet_dest: this.state.addressJudet,
      colete: "1",
      greutate: this.getTotalQuantity(),
    };

    data = this.jsonToFormData(data);

    var url ="https://www.selfawb.ro/tarif.php"

    axios({
      method: 'post',
      url: url,
      data: data
    }).then( (response) => {
      var amount = parseFloat(response.data);
        amount = amount.toFixed(2);
        amount = parseFloat(amount);
        amount = amount * 1.21
        amount = Math.ceil(amount);
        if (amount) {
          this.setState({ shippingPrice: amount, hasShippingInfo: true });
        } else {
          alert(response.data);
        }
    });
    }else{
      alert("Specifică strada.")
    }
  };

  createAWB = (test) => {
    var plata 
    var rambursSuma = ""
    var rambursLa = ""
    if(this.state.onlinePayment){
      plata = "expeditor"
    }else{
      plata = "destinatar"
      rambursSuma = this.getTotal()
      rambursLa = "expeditor"
    }
    var data = {
      items: [
        {
          "Tip serviciu": "Standard",
          Banca: "",
          IBAN: "",
          "Nr. Plicuri": 0,
          "Nr. Colete": 1,
          Greutate: this.getTotalQuantity(),
          "Plata expeditie":plata,
          "Ramburs(bani)": rambursSuma,
          "Plata ramburs la": rambursLa,
          "Valoare declarata": "",
          "Persoana contact expeditor": "Woodname SRL",
          Observatii: "",
          Continut: "",
          "Nume destinatar": JSON.parse(localStorage.getItem("tg-name")),
          "Persoana contact": "",
          Telefon: JSON.parse(localStorage.getItem("tg-number")),
          Fax: "",
          Email: JSON.parse(localStorage.getItem("tg-email")),
          Judet: JSON.parse(localStorage.getItem("tg-address-judet")),
          Localitatea: JSON.parse(localStorage.getItem("tg-address-location")),
          Strada: JSON.parse(localStorage.getItem("tg-address-street")),
          Nr: JSON.parse(localStorage.getItem("tg-address-number")),
          "Cod postal": JSON.parse(localStorage.getItem("tg-address-code")),
          Bloc: "",
          Scara: "",
          Etaj: "",
          Apartament: "",
          "Inaltime pachet": "",
          "Latime pachet": "",
          "Lungime pachet": "",
          Restituire: "",
          "Centru Cost": "",
          Optiuni: "",
          Packing: "",
          "Date personale": "",
        },
      ],
    };

    const items = data.items;
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var payload
    if(test){
      payload = {
            username: "clienttest",
            user_pass: "testing",
            client_id: "7032158",
          };
    }else{
      payload = {
        username: "woodname",
        user_pass: "mebesobev",
        client_id: "7178047",
      };
    }


    payload = this.jsonToFormData(payload);

    payload.append("fisier", blob);

    axios({
      method: 'post',
      url: "https://www.selfawb.ro/import_awb_integrat.php",
      data: payload
    }).then((response) => {
        var result = response.data
        var success = result.slice(2,3)
        success = parseInt(success)
        if(!success){
          var error = result.slice(result.indexOf(',', 5)+1)
          alert(error)
        }else{
          if(test){
            this.onCommand()
          }else{
            var AWB 
            var amount
            var startAWB = result.indexOf(',', 3)
            var endAWB = result.indexOf(",",5)
            AWB = result.slice(startAWB+1, endAWB)
            var endAmount = result.indexOf(',', endAWB+1)
            amount = result.slice(endAWB+1, endAmount)
            amount = parseFloat(amount)
            amount = amount * 1.21
            amount = Math.ceil(amount)

            var email_text = JSON.parse(localStorage.getItem("email_text"))

            email_text +=
            "<br/><b>AWB: </b>" +
            AWB +
            "<br/>Achitare Livrare: "+plata+"<br/>"

            if(!this.state.onlinePayment){
              this.setState({
                confirmation: true,
                deliveryCost: amount,
                AWBNumber: AWB,
              })
              email_text +=
              "<b>Suma pentru livrare: </b>" +
              amount +"<br/>"
              localStorage.setItem("email_text", JSON.stringify(email_text));
            }else{
              email_text +=
              "<b>Achitat online!</b>"
              localStorage.setItem("email_text", JSON.stringify(email_text));
              this.sendEmail()
            }
            
          }
        }
      });
  };

  onCommand = () => {
    var all_ok = true;
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const comments = document.getElementById("comments").value;
    var hasFile = false;

    const id = Date.now();

    if (name.length < 3) {
      alert("Numele nu este valid.");
      all_ok = false;
      return;
    }
    if (email.length < 10 || !email.includes("@") || !email.includes(".")) {
      alert("Email-ul nu este valid.");
      all_ok = false;
      return;
    }
    if (phone.length < 9) {
      alert("Numarul de telefon nu este valid.");
      all_ok = false;
      return;
    }

    if (all_ok) {
      var email_text =
        "Nume: " +
        name +
        "<br/>Telefon: " +
        phone +
        "<br/>Email: " +
        email +
        "<br/>Comentarii: " +
        comments +
        "<br/><b>Total: " +
        this.getTotal() +
        " Lei</b><br/>Comanda: <br/><br/>";

      for (let i = 0; i < this.state.basket.length; i++) {
        email_text +=
          i +
          1 +
          ". " +
          this.state.basket[i].name +
          ": " +
          this.state.basket[i].nr +
          "<br/>Cantitate: " +
          this.state.basket[i].quantity +
          "<br/>Personalizarea: " +
          this.state.basket[i].comment +
          "<br/>" +
          this.state.basket[i].img +
          "<br/><br/>";
      }

      if (this.state.fileName) {
        let file = document.getElementById("files").files[0];
        hasFile = true;
        let ref = this.props.storage.ref();
        var uploadTask = ref.child("images/" + id).put(file);
        uploadTask.on("state_changed", () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            email_text += "<br/>File: " + downloadURL + "<br/>";
            localStorage.setItem("email_text", JSON.stringify(email_text));
          });
        });
      } else {
        localStorage.setItem("email_text", JSON.stringify(email_text));
      }

      this.props.database
        .ref(id)
        .set({
          numele: name,
          email: email,
          telefon: phone,
          comentarii: comments,
          timestamp: id,
          basket: this.state.basket,
          file: hasFile,
        })
        .then(() => {
          localStorage.setItem("order_id", JSON.stringify(id));
          if(this.state.onlinePayment){
            this.stripePayment();
          }else{
            this.createAWB();
          }
        });
    }
  };

  sendEmail = () => {
    var id = JSON.parse(localStorage.getItem("order_id"));
    this.props.firestore
      .collection("mail")
      .add({
        to: "infotree217@gmail.com, elviratgdecor@gmail.com",
        message: {
          subject: "Comanda noua TG-decor.ro",
          text: JSON.parse(localStorage.getItem("email_text")),
          html: JSON.parse(localStorage.getItem("email_text")),
        },
      })
      .then(() => {
        if(this.state.onlinePayment){
          this.props.database
          .ref(id)
          .update({
            onlinePayment: true,
          })
          .then(() => {
            this.setState({thank_you:true})
            localStorage.clear();
          });
        }else{
          localStorage.clear();
          this.setState({thank_you:true, confirmation:false})
        }
        
      });
  };

  cancelOrder = () => {
    var id = JSON.parse(localStorage.getItem("order_id"));
    this.props.database
    .ref(id)
    .update({
      canceled: true,
      deliveryCost: this.state.deliveryCost,
      deliveryApproximation:this.state.shippingPrice,
    })

    var data = {
      AWB: this.state.AWBNumber,
      username: "woodname",
      user_pass: "mebesobev",
      client_id: "7178047",
    };

    data = this.jsonToFormData(data);

    axios({
      method: 'post',
      url: "https://www.selfawb.ro/delete_awb_integrat.php",
      data: data
    })
      .then((response) => {
          this.setState({confirmation:false})
      })

  }

  getTotal() {
    var total = 0;
    var tempBasket = JSON.parse(localStorage.getItem("tg-basket"))
    for (let i = 0; i < tempBasket.length; i++) {
      total += tempBasket[i].quantity * tempBasket[i].priceRO;
    }
    return parseInt(total);
  }

  checkFile = () => {
    let fileName = document.getElementById("files").files[0].name;
    if (fileName) {
      this.setState({ fileName });
    }
  };

  stripePayment = async (event) => {
    const functions = this.props.functions;
    const stripe = await stripePromise;
    var payment = functions.httpsCallable("payment");
    var amountToPay = this.getTotal()+this.state.shippingPrice
    amountToPay = amountToPay*100
amountToPay = amountToPay.toFixed(0)

    payment({ amount: amountToPay}).then((result) => {
      stripe.redirectToCheckout({
        sessionId: result.data.id,
      });
    });
  };

  // checkPrice = () => {
  //   var data = {
  //     username: "woodname",
  //     user_pass: "mebesobev",
  //     client_id: "7178047",
  //     serviciu: "Standard",
  //     localitate_dest: this.state.addressLocation,
  //     judet_dest: this.state.addressJudet,
  //     colete: "1",
  //     greutate: this.getTotalQuantity(),
  //   };

  //   var payload = this.jsonToFormData(data);
  //   const functions = this.props.functions;
  //   var check = functions.httpsCallable("checkPrice");

  //   check({ payload: payload }).then((result) => {
  //     console.log(result)
  //   });
  // }
  changePaymentMethod = (e) => {
    this.setState({ onlinePayment: e.target.value });
  };

  render() {
    if (this.state.thank_you) {
      return <Multumim />;
    }
    if (this.state.confirmation) {
      return(
        <div>
          <Header/>
            <div>
              <h1 style={{textAlign:"center"}}>Confirmă te rog comanda.</h1>
              <b
                    style={{
                      lineHeight: "14px",
                      marginTop: "10px",
                      textAlign: "center",
                      color: "rgb(131,27,41)",
                    }}
                    className="checkout-input"
                  >
                    Suma exactă Fan Courier: {this.state.deliveryCost} Lei
                  </b>{" "}
                  <h2 style={{ color: "rgb(131,27,41)" }}>
                    Total: {this.getTotal() + this.state.deliveryCost} Lei
                  </h2>
                    <Button className="checkout-input" type="primary" onClick={()=>this.sendEmail()}>Confirm</Button>
                    <Button className="checkout-input" type="text" style={{border:'none'}} onClick={()=>this.cancelOrder()}>Anulează</Button>

            </div>
          <Footer/>
        </div>
      )
    } else {
      return (
        <div>
          <Header />
          {this.state.basket != null ? (
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "hidden",
                  justifyContent: "center",
                }}
              >
                {JSON.parse(localStorage.getItem("tg-basket")).map(
                  (item, index) => (
                    <div key={index} style={{ width: "280px", margin: "20px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ lineHeight: "40px" }}>
                          {item.name} nr {item.nr}
                        </h4>
                        <Button
                          onClick={() => this.removeFromBasket(index)}
                          style={{ float: "right", margin: "5px 0px" }}
                          type="danger"
                        >
                          X
                        </Button>
                      </div>
                      <img
                        src={item.img}
                        alt={item.name}
                        style={{ width: "100%" }}
                      ></img>
                      <i>Personalizare: </i>{" "}
                      <TextArea
                        value={item.comment}
                        rows={3}
                        placeholder="Detalii de presonalizare. Exemplu: nume, prenume, ziua de naștere, etc."
                        onChange={(e) => this.updateComment(e, index)}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px 0px",
                        }}
                      >
                        <p style={{ lineHeight: "34px" }}>Cantitatea:</p>{" "}
                        <Button
                          onClick={() => this.subQuantity(index, item.quantity)}
                        >
                          -
                        </Button>
                        <h4 style={{ lineHeight: "34px" }}>{item.quantity}</h4>
                        <Button onClick={() => this.addQuantity(index)}>
                          +
                        </Button>
                      </div>
                      <b
                        style={{
                          lineHeight: "14px",
                          marginTop: "10px",
                          color: "rgb(131,27,41)",
                        }}
                      >
                        Preț: {item.priceRO} Lei &times; {item.quantity} ={" "}
                        {item.priceRO * item.quantity} Lei
                      </b>{" "}
                      <Divider />
                    </div>
                  )
                )}
              </div>
              {this.state.hasShippingInfo ? (
                <div>
                  <Input
                    id="name"
                    placeholder="Nume și Prenume"
                    className="checkout-input"
                    value={this.state.userName}
                    onChange={(e) => this.updateName(e)}
                  ></Input>
                  <Input
                    id="phone"
                    placeholder="Număr de contact"
                    className="checkout-input"
                    value={this.state.userNumber}
                    onChange={(e) => this.updateNumber(e)}
                  ></Input>
                  <Input
                    id="email"
                    placeholder="Email"
                    className="checkout-input"
                    value={this.state.userEmail}
                    onChange={(e) => this.updateEmail(e)}
                  ></Input>
                  <TextArea
                    id="comments"
                    rows={4}
                    placeholder="Comentarii"
                    className="checkout-input"
                    value={this.state.userComment}
                    onChange={(e) => this.updateUserComment(e)}
                  ></TextArea>
                  <Button
                    className="checkout-input"
                    onClick={() => document.getElementById("files").click()}
                  >
                    Adaugă file...
                  </Button>
                  {this.state.fileName && (
                    <p className="checkout-input">{this.state.fileName}</p>
                  )}
                  <input
                    className="checkout-input"
                    id="files"
                    type="file"
                    style={{ visibility: "hidden" }}
                    onChange={() => this.checkFile()}
                    tag="alege"
                  ></input>
                  <b
                    style={{
                      lineHeight: "14px",
                      marginTop: "10px",
                      textAlign: "center",
                      color: "rgb(131,27,41)",
                    }}
                    className="checkout-input"
                  >
                    Fan Courier: {this.state.shippingPrice} Lei
                  </b>{" "}
                  <h2 style={{ color: "rgb(131,27,41)" }}>
                    Total: {this.getTotal() + this.state.shippingPrice} Lei
                  </h2>
                  <Radio.Group
                    className="checkout-input"
                    onChange={(e) => this.changePaymentMethod(e)}
                    value={this.state.onlinePayment}
                  >
                    <Radio value={1}>Achitare Online</Radio>
                    <Radio value={0}>Achitare Cash</Radio>
                  </Radio.Group>
                  <Button
                    className="checkout-input"
                    type="primary"
                    onClick={() => this.createAWB(true)}
                  >
                    {this.state.onlinePayment
                      ? "Achită online"
                      : "Trimite Comanda"}
                  </Button>
                  <Button
                    className="checkout-input"

                    onClick={() => this.setState({hasShippingInfo:false})}
                  >
                    Înapoi
                  </Button>
                </div>
              ) : (
                <div>
                  <h3 style={{ textAlign: "center" }}>Livrare:</h3>
                  <Input
                    placeholder="Strada"
                    className="checkout-input"
                    value={this.state.addressStreet}
                    onChange={(e) => this.updateStreet(e)}
                  ></Input>
                  <Input
                    placeholder="Nr."
                    className="checkout-input"
                    value={this.state.addressNumber}
                    onChange={(e) => this.updateStrNumber(e)}
                  ></Input>
                  <Input
                    placeholder="Localitatea"
                    className="checkout-input"
                    value={this.state.addressLocation}
                    onChange={(e) => this.updateLocation(e)}
                  ></Input>
                  <Input
                    placeholder="Județul"
                    className="checkout-input"
                    value={this.state.addressJudet}
                    onChange={(e) => this.updateJudet(e)}
                  ></Input>
                  <Input
                    placeholder="Codul Poștal"
                    className="checkout-input"
                    value={this.state.addressCode}
                    onChange={(e) => this.updatePostal(e)}
                  ></Input>
                  <Button
                    className="checkout-input"
                    type="primary"
                    onClick={() => this.checkFC()}
                  >
                    Următorul pas
                  </Button>
                  {/* <Button onClick={() => this.checkPrice()}>Check</Button> */}
                </div>
              )}
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "30vh 0px" }}>
              Coșul este gol. <a href="/"> Alege produsul dorit.</a>
            </h1>
          )}
          <Footer />
        </div>
      );
    }
  }
}
export default Checkout;
