import React, { Component } from "react";
import { Row, Col, Button, Drawer, Divider, Input } from "antd";
import logo from "../img/logo.png";
const { TextArea } = Input;

export default class Header extends Component {
  state = {
    visible: false,
  };

  componentDidMount = () => {
    this.setState({ visible: this.props.visible });
  };
  onClick = (h) => {
    window.location.assign(h);
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };
  onClose = () => {
    this.setState({ visible: false });
  };

  clearBasket = () => {
    this.setState({ visible: false });
    localStorage.clear();
  };

  removeFromBasket = (index) => {
    let list = JSON.parse(localStorage.getItem("tg-basket"));
    let temp = JSON.parse(localStorage.getItem("tg-basketIds"));
    list.splice(index, 1);
    temp.splice(index, 1);
    if (list.length < 1) {
      localStorage.clear();
    } else {
      localStorage.setItem("tg-basket", JSON.stringify(list));
      localStorage.setItem("tg-basketIds", JSON.stringify(temp));
    }
    this.onClose();
    this.showDrawer();
  };

  updateComment = (e, index) => {
    let list = JSON.parse(localStorage.getItem("tg-basket"));
    list[index].comment = e.target.value;
    localStorage.setItem("tg-basket", JSON.stringify(list));
    this.onClose();
    this.showDrawer();
  };

  addQuantity = (index) => {
    let list = JSON.parse(localStorage.getItem("tg-basket"));
    list[index].quantity = list[index].quantity + 1;
    localStorage.setItem("tg-basket", JSON.stringify(list));
    this.onClose();
    this.showDrawer();
  };

  subQuantity = (index, quantity) => {
    if (quantity === 1) {
      this.removeFromBasket(index);
    } else {
      let list = JSON.parse(localStorage.getItem("tg-basket"));
      list[index].quantity = list[index].quantity - 1;
      localStorage.setItem("tg-basket", JSON.stringify(list));
    }
    this.onClose();
    this.showDrawer();
  };

  render() {
    return (
      <div className="header">
        <Row>
          <Col style={{ width: "30%" }}>
            {" "}
            <img
              className="logo"
              alt={"Cadouri personalizate de sarbatori, de la TG Decor"}
              onClick={() => this.onClick("/")}
              src={logo}
            ></img>
          </Col>
          <Col className=" center-link" style={{ width: "70%" }}>
            <Button href="#footer" className="center-button">
              Contactați-ne
            </Button>
            <Button
              type="primary"
              className="center-button"
              onClick={() => this.showDrawer()}
            >
              Coș
            </Button>
            <Drawer
              title="Coș"
              placement="right"
              id="cos"
              closable={true}
              onClose={() => this.onClose()}
              visible={this.state.visible}
              width="276"
              bodyStyle={{ overflowX: "hidden" }}
            >
              {localStorage.getItem("tg-basket") != null ? (
                <div>
                  {JSON.parse(localStorage.getItem("tg-basket")).map(
                    (item, index) => (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 style={{ lineHeight: "40px" }}>
                            {item.name} nr {item.nr}
                          </h4>
                          <Button
                            onClick={() => this.removeFromBasket(index)}
                            style={{ float: "right", margin: "5px 0px" }}
                            type="danger"
                          >
                            X
                          </Button>
                        </div>
                        <img
                          src={item.img}
                          alt={item.name}
                          style={{ width: "100%" }}
                        ></img>
                        <i>Personalizare: </i>{" "}
                        <TextArea
                          value={item.comment}
                          rows={3}
                          placeholder="Detalii de presonalizare. Exemplu: nume, prenume, ziua de naștere, etc."
                          onChange={(e) => this.updateComment(e, index)}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px 0px",
                          }}
                        >
                          <p style={{ lineHeight: "34px" }}>Cantitatea:</p>{" "}
                          <Button
                            onClick={() =>
                              this.subQuantity(index, item.quantity)
                            }
                          >
                            -
                          </Button>
                          <h4 style={{ lineHeight: "34px" }}>
                            {item.quantity}
                          </h4>
                          <Button onClick={() => this.addQuantity(index)}>
                            +
                          </Button>
                        </div>
                        <b
                          style={{
                            lineHeight: "14px",
                            marginTop: "10px",
                            color: "rgb(131,27,41)",
                          }}
                        >
                          Preț: {item.priceRO} Lei &times; {item.quantity} ={" "}
                          {item.priceRO * item.quantity} Lei
                        </b>{" "}
                        <Divider />
                      </div>
                    )
                  )}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button onClick={() => this.clearBasket()} type="danger">
                      Șterge tot
                    </Button>
                    <Button href="/checkout" type="primary">
                      Continuă
                    </Button>
                  </div>
                </div>
              ) : (
                <div>Coșul este gol!</div>
              )}
            </Drawer>
          </Col>
        </Row>
      </div>
    );
  }
}
